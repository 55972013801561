@import './mixin.scss';

th, td {
  border-bottom: 1px dashed #BFC9CA;
  border-collapse: collapse;
}

.input-query {
  width: 200px;

  @include for-tablet-down {    
    width: 100%;
  }
}

.table-contain {

  @include for-tablet-down {    
      max-width: 100vw;
      overflow: scroll;
  }
}

th, td {
  padding: 15px 10px 15px 10px;
}
@import './mixin.scss';

.profile-tab{
    padding: 5px 10px;
    cursor: pointer;
  
    &:hover{
      color: #008561;
      border-bottom: 2px solid #008561;
    }
  }
  
  .active-profile{
    border-bottom: 2px solid #008561;
    color: #008561;
  }
  
  .single-plan{
    border-radius: 6px;
    background-color: #EBF5FB;
    height: 550px;
    padding: 15px;
    border: 1px solid #D6EAF8;
  }
  
  .active-plan-button{
    background-color: #008561;
    border-radius: 6px;
    color: white;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  
    &:hover{
      background-color: #45B39D;
    }
  }
  
  .current-plan-button{
    background-color: #F2F3F4;
    border-radius: 6px;
    border: 1px solid #D7DBDD;
    padding: 10px;
    text-align: center;
    margin-top: 10px;
    cursor: default;
  }
  
  .change-password-button{
    background-color: #F2F3F4;
    border-radius: 6px;
    border: 1px solid #D7DBDD;
    padding: 7px 10px;
    text-align: center;
    cursor: pointer;
  }

  .Add-phone-modal {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));

    @include for-phone-only {    
        display: block;
    }

    .Add-phone-menu {
        border-right: 1px solid #E0E0E0;
        grid-column: span 2 / span 2;
        h2 {
            font-size: 18px;
            font-weight: 500;
            color: #000;
            margin: 24px 0;
            padding-left: 44px;
        }
        .Add-Phone {
            list-style: none;
            padding: 0;
            margin-bottom: 24px;
        }

        .Create-phone-menu-item {
            display: flex;
            align-items: center;
            gap: 27px;
            padding: 12px 0 12px 24px;

            div {
                width: 25px;
                height: 25px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            p {
                font-size: 14px;
                margin: 0;
            }

        }

        .active-menu-item {
            background-color: #0085611C;

            div {
                border: 2px solid #008561;
            }

            p {
                color: #008561;
            }

        }

        .Submitted-menu {
            border: 2px solid #008561 !important;
            background-color: #008561;

            svg {
                color: white;
                font-size: 28px;
            }
        }

        .inactive-menu-item {
            div {
                border: 3px solid #808080;
            }

            p {
                color: #808080;
            }
        }
    }

    .Add-phone-forms {
        grid-column: span 4 / span 4;
        padding: 0 34px;

        h2 {
            font-size: 18px;
            font-weight: 500;
            color: #000;
            margin: 24px 0 9px 0;
        }

        .Add-phone-form-info {
            color: #808080;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
  }
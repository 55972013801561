@import './mixin.scss';

.user-menu-container{
  position: relative;
}

.user-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  top: 50px;
  right: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 80;
}

.user-menu-visible {
  display: block;
}

/* Links inside the dropdown */
.user-menu span {
  float: none;
  cursor: pointer;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.user-menu span:hover {
  background-color: #ddd;
}

.profile-tab{
  padding: 5px 10px;
  cursor: pointer;

  &:hover{
    color: #0095ff;
    border-bottom: 2px solid #0095ff;
  }
}

.active-profile{
  border-bottom: 2px solid #0095ff;
  color: #0095ff;
}

.shipments-table-container{
  overflow-y: hidden;
  // max-height: 500px;
  min-height: 500px;

  &:hover{
    overflow-y: scroll;
  }

  .shipments-table{
    width: 100%;

    th{
      position: sticky;
      top: 0;
      background: #F8F9F9;
    }

    th, td {
      border-bottom: 1px dashed #BFC9CA;
      border-collapse: collapse;
    }

    th, td {
      padding: 15px 10px 15px 10px;
    }
  }

  tr {
    cursor: pointer;

    &:hover {
      background-color: aliceblue;
    }
  }
}

.show-customer-tab{
  display: block;
}

.hide-customer-tab{
  display: none;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.documents-contain {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 4rem;

  @include for-tablet-down {    
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .document-card {
    width: 100%;
    border: 1px solid #BFC9CA;
    border-radius: 10px;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    
    &:hover {
      transform: scale(1.02);
    }

    svg {
      color: #BFC9CA;
      font-size: 2.4rem;
      font-weight: 600;
    }
  }

}


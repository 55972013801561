a{
    color: #008561;

    &:hover{
        color: #45B39D;
    }
}

input[type='checkbox']{
    margin-right: 10px;
    border-color: #008561;

    &:checked{
        background-color: #008561;
        border-color: #008561;
    }

    &:active, &:focus{
        box-shadow: none;
    }
}

input[type='radio']{
    margin-right: 10px;

    &:checked{
        background-color: #008561;
        border-color: #008561;
    }

    &:active, &:focus{
        box-shadow: none;
    }
}

.confirm-button {
    background-color: #008561;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover{
        background-color: #45B39D;
    }

    &:active {
        background-color: #008561;
        box-shadow: none;
    }
}

.oval-button {
    background-color: #008561;
    border: 1px solid transparent;
    border-radius: 20px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    //font-weight: 400;
    //line-height: 1.15385;
    margin: 0;
    outline: none;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover{
        background-color: #45B39D;
    }

    &:active {
        background-color: #008561;
        box-shadow: none;
    }
}

.cancel-button {
    background-color: #CCD1D1;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #424949;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover{
        background-color: #5D6D7E;
        color: white;
    }
}

.cancel-red-button {
    background-color: #f46a6a;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover,
    &:focus {
        background-color: #D98880;
    }

    &:active {
        background-color: #D98880;
        box-shadow: none;
    }
}

.previous-button {
    background-color: #D5DBDB;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #2E4053;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.15385;
    margin: 0;
    outline: none;
    padding: 8px .8em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;

    &:hover,
    &:focus {
        background-color: #B2BABB;
        box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
    }

    &:active {
        background-color: #B2BABB;
        box-shadow: none;
    }
}

.notification {
    max-width: 430px;
    max-height: 200px;
    overflow: hidden;
    padding: 12px 48px 12px 12px;
    z-index: 99;
    font-weight: bold;
    position: relative;
}

.notification:not(:last-child) {
    margin-bottom: 8px;
}

.notification.info {
    background-color: #2196f3;
}

.notification.success {
    background-color: #4caf50;
}

.notification.warning {
    background-color: #ff9800;
}

.notification.error {
    background-color: #f44336;
}

.notification .closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}

.notification,
.notification .closeButton {
    color: #fff;
}

.countries-text-field button {
    background-color: #fff;
    border-radius: 7px;
    border: 1px solid #ced4da;
    padding: 2px 10px;
}

// .form-control:focus {
//     border: 1px solid #ced4da;
//     border: none !important;
//     outline: none !important;
//     box-shadow: none !important;
// }

.form-control:focus {
    border: 1px solid #ced4da;
    outline: none !important;
    box-shadow: none !important;
}

.form-select:focus {
    border: 1px solid #ced4da;
    outline: none !important;
    box-shadow: none !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;

}
.profile-tab{
  padding: 5px 10px;
  cursor: pointer;

  &:hover{
    color: #008561;
    border-bottom: 2px solid #008561;
  }
}

.active-profile{
  border-bottom: 2px solid #008561;
  color: #008561;
}

.single-plan{
  border-radius: 6px;
  background-color: #EBF5FB;
  height: 550px;
  padding: 15px;
  border: 1px solid #D6EAF8;
}

.active-plan-button{
  background-color: #008561;
  border-radius: 6px;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;

  &:hover{
    background-color: #45B39D;
  }
}

.current-plan-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: default;
}

.change-password-button{
  background-color: #F2F3F4;
  border-radius: 6px;
  border: 1px solid #D7DBDD;
  padding: 7px 10px;
  text-align: center;
  cursor: pointer;
}

.plan-pricing {
  font-size: 35px;
}

.pricing-discount {
  font-size: 20px;
  opacity: .5;
  text-decoration: line-through;
}
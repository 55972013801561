@import './mixin.scss';

.sidebar {
    width: 250px;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    border-right: 0.5px solid rgb(244, 244, 244);

    &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
    }

    &::-webkit-scrollbar-track {
        background: white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ddd;
        visibility: hidden;
        border-radius: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    hr {
        height: 0;
        border: 0.5px solid rgb(244, 244, 244);
    }

    .logo {
        height: 60px;
        position: fixed;
        background-color: white;
        padding: 5px;
        padding-left: 10px;
        z-index: 10;
        display: flex;
        width: 250px;
        border-right: 1.5px solid #EBEDEF;
        border-bottom: 1.5px solid #EBEDEF;

        .details {
            width: 100%;
            padding-left: 5px;
            border-radius: 5px;

            .operator-name {
                margin: 0;
                text-transform: uppercase;
                color: #343a40;
            }

            .operator-id {
                font-size: 13px;
                margin-top: -3px;
            }
        }
    }

}

.menu {
    margin-top: 60px;

    @include for-tablet-down {    
        margin-top: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        a {
            text-decoration: none;
            color: #74788d;
        }

        li {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            align-items: center;
            padding-left: 20px;
            padding-top: 12px;
            padding-right: 20px;
            padding-bottom: 12px;

            .icon {
                font-size: 18px;
                margin-right: 5px;
            }

            .chevron-arrow {
                float: right;
            }

            &:hover {
                background-color: #f3f8fb;
                color: #45B39D;
            }
        }
    }

    li.submenu-item {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 55px;
        cursor: pointer;
        font-size: 15.5px;

        &:hover {
            background-color: #f3f8fb;
            color: #45B39D !important;
        }

        &.a:hover {
            color: #45B39D !important;
        }
    }
}
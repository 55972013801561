@import './mixin.scss';

.shipments-container {
    
    .shipment-top-input {
        width: 230px;

        @include for-tablet-down {    
            width: 100%;
        }
    }

    .table-contain {

        @include for-tablet-down {    
            max-width: 100vw;
            overflow: scroll;
        }
    }

    .shipments-table{

        th, td {
            border-bottom: 1px dashed #BFC9CA;
            border-collapse: collapse;
        }

        th, td {
            padding: 15px 10px 15px 10px;
        }
    }

    tr {
        cursor: pointer;

        &:hover {
            background-color: aliceblue;
        }
    }
}

.top-filter-section {
    width: 50%;

    @include for-tablet-down {    
        width: 100%;
    }
}

